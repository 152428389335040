import React from "react";
import { Link } from "gatsby";

import Head from "../components/head";
import Layout from "../components/layout";
import Section from "../components/section";

const PageTitle = "Menu";

export default () => (
  <div>
    <Head PageTitle={PageTitle} PageSlug="/menu/" />

    <Layout>
      <Section Style="secondary uk-light uk-text-center">
        <ul className="uk-nav uk-nav-primary uk-nav-center">
          <ListLink to="/projects/">Réalisations</ListLink>
          <ListLink to="/services/">Services</ListLink>
          <ListLink to="/skills/">Compétences</ListLink>
          <ListLink to="/about/">À propos</ListLink>
          <ListLink to="/contact/">Contact</ListLink>
          <hr/>
          <ListLink to="/">Accueil</ListLink>
          <ListLink to="/sitemap/">Plan du site</ListLink>
        </ul>
      </Section>
    </Layout>
  </div>
);

const ListLink = props => (
  <li>
    <Link
      to={props.to}
      className="uk-button uk-link-heading item-menu"
      activeClassName="active-menu"
    >
      {props.children}
    </Link>
  </li>
);
